
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins } from "vue-property-decorator";
import {
  NavBar,
  Row,
  Col,
  Icon,
  Image,
  List,
  Cell,
  Button,
  Dialog,
} from "vant";
import wx from "weixin-js-sdk";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [List.name]: List,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Dialog.Component.name]: Dialog.Component,
  },
})
export default class ExchangeRecord extends Mixins(Mixin) {
  public RecordList: Array<member.PrintingExchangeDTO> = [];
  public list = {
    loading: false,
    finished: false,
  };
  public page = {
    current: 1,
    size: 10,
  };
  getOrderStatus(orderCode: string | undefined): string {
    let text = "";
    switch (orderCode) {
      case "01":
        text = "待领取";
        break;
      case "02":
        text = "已完成";
        break;
      case "03":
        text = "已取消";
        break;
      case "04":
        text = "待付款";
        break;
      case "05":
        text = "待发货";
        break;
      case "06":
        text = "待收货";
        break;
    }
    return text;
  }
  getRecords(): void {
    this.$api.memberApi.printing.getExchangePrintingList(({ data }) => {
      if (
        this.ChannelModule.channel === "plat_ios" ||
        this.ChannelModule.channel === "plat_android"
      ) {
        this.jsBridge("isShowShare", "0");
      }
      this.RecordList = data;
      this.list.loading = true;
      this.list.finished = true;
    });
  }
  goPrintOrderDetail(item: member.PrintingExchangeDTO): void {
    // 打开订单详情页
    if (this.ChannelModule.channel === "plat_h5") {
      this.$router.push(`/mall/m-order/${item.orderId}?source=print`);
    } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
      wx.miniProgram.navigateTo({
        url: `/pagesOrder/goodsOrder/goodsOrder?orderId=${item.orderId}&theaterId=${this.TheaterModule.theaterId}&pageSources="orderList"`,
      });
    } else if (
      this.ChannelModule.channel === "plat_ios" ||
      this.ChannelModule.channel === "plat_android"
    ) {
      this.jsBridge(
        "goMallOrder",
        item.orderId ? `${Number(item.orderId)}` : "0"
      );
    }
  }
  delRecords(orderId: string | undefined): void {
    // TODO 删除记录
    if (orderId) {
      //接口调用
      Dialog.confirm({
        message: "您确定删除此记录吗？",
      }).then(() => {
        this.$api.orderApi.goodOrder.deleteGoodOrder(orderId, ({ data }) => {
          if (data) {
            this.$toast.success("删除成功");
            this.getRecords(); // 删除成功后，调一下接口刷新数据
          }
        });
      });
    }
  }
  pay(item: member.PrintingExchangeDTO): void {
    // 调起支付
    if (item.orderId) {
      if (this.ChannelModule.channel === "plat_h5") {
        this.$router.push(
          `/payconfirm?orderId=${item.orderId}&orderType=COMMODITY`
        );
      } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
        // TODO 调起小程序支付
        let params = {
          actuallyPaidAmt: item.actuallyPaidAmt,
          orderExpireTime: item.createTime,
          orderId: item.orderId,
          orderType: "COMMODITY",
        };
        wx.miniProgram.navigateTo({
          url: `/pagesOrder/confirmPayment/confirmPayment?par=${JSON.stringify(
            params
          )}`,
        });
      } else if (
        this.ChannelModule.channel === "plat_ios" ||
        this.ChannelModule.channel === "plat_android"
      ) {
        // 调起app支付
        this.jsBridge(
          "paymentPrinting",
          `orderId/${item.orderId},actuallyPaidAmt/${
            item.actuallyPaidAmt ? item.actuallyPaidAmt.toFixed(2) : 0
          },orderExpireTime/${item.createTime}`
        );
      }
    }
  }
}
