var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ExchangeRecord"},[(_vm.ChannelModule.channel === 'plat_h5')?_c('van-nav-bar',{staticClass:"navbar",attrs:{"title":"兑换记录","left-arrow":""},on:{"click-left":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('close')}}}):_vm._e(),_c('div',{staticClass:"container",style:(_vm.ChannelModule.channel === 'plat_h5'
        ? `padding-top:0.98rem`
        : `padding-top:0`)},[(_vm.RecordList.length === 0)?_c('van-row',{staticClass:"no-data"},[_c('van-image',{staticClass:"no-data-img",attrs:{"fit":_vm.imageFit,"src":require('@/assets/images/CollectPrint/no-data.png')}}),_c('p',{staticClass:"no-data-text"},[_vm._v("还没有记录哦~")])],1):_vm._e(),(_vm.RecordList.length > 0)?_c('van-row',{staticClass:"split-bar"}):_vm._e(),(_vm.RecordList.length > 0)?_c('van-list',{staticClass:"printing-list",style:({
        padding:
          _vm.ChannelModule.channel === 'plat_h5'
            ? ' 0.17rem 0.3rem 0 0.3rem'
            : '0.17rem 0 0 0',
      }),attrs:{"finished":_vm.list.finished,"immediate-check":false,"finished-text":"没有更多记录了"},on:{"load":_vm.getRecords},model:{value:(_vm.list.loading),callback:function ($$v) {_vm.$set(_vm.list, "loading", $$v)},expression:"list.loading"}},_vm._l((_vm.RecordList),function(item,index){return _c('van-row',{key:index,staticClass:"record-item",style:({
          height:
            item.orderStatus === '02' ||
            item.orderStatus === '03' ||
            item.orderStatus === '04'
              ? '4.49rem'
              : '3.6rem',
        })},[_c('van-col',{staticClass:"item-top",on:{"click":function($event){return _vm.goPrintOrderDetail(item)}}},[_c('p',{staticClass:"order-code"},[_vm._v(_vm._s(item.orderNumber))]),_c('p',{staticClass:"order-status"},[_vm._v(" "+_vm._s(_vm.getOrderStatus(item.orderStatus))+" ")])]),_c('van-col',{staticClass:"item-center",on:{"click":function($event){return _vm.goPrintOrderDetail(item)}}},[_c('van-row',{staticClass:"left"},[_c('van-image',{staticClass:"image",attrs:{"fit":_vm.imageFit,"src":item.commodityPicture}})],1),_c('van-row',{staticClass:"right"},[_c('van-col',{staticClass:"title two-omit"},[_vm._v(_vm._s(item.commodityTitle))]),_c('van-col',{staticClass:"times"},[_vm._v(_vm._s(_vm.timestampFormat( item.createTime ? Number(item.createTime) : 0, "YYYY.MM.DD HH:mm" )))]),_c('van-col',{staticClass:"count"},[(item.actuallyPaidAmt && item.actuallyPaidAmt > 0)?_c('span',{staticClass:"amount"},[_vm._v("￥"+_vm._s(item.actuallyPaidAmt.toFixed(2))+" + ")]):_vm._e(),_c('span',{staticClass:"print"},[_vm._v(_vm._s(item.paymentPrinting)+"印花 ")]),_c('span',{staticClass:"num"},[_vm._v("/   共"+_vm._s(item.exchangeNumber)+"件")])])],1)],1),(
            item.orderStatus === '02' ||
            item.orderStatus === '03' ||
            item.orderStatus === '04'
          )?_c('van-col',{staticClass:"item-bottom"},[(item.orderStatus === '02' || item.orderStatus === '03')?_c('van-button',{staticClass:"del-btn",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.delRecords(item.orderId)}}},[_vm._v("删除记录")]):_vm._e(),(item.orderStatus === '04')?_c('van-button',{staticClass:"pay-btn",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.pay(item)}}},[_vm._v("立即支付")]):_vm._e()],1):_vm._e()],1)}),1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }